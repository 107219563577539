import React,{Component} from 'react';
import Modal from 'react-modal';
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
class Paramform extends Component {

    constructor(props) {
        super(props);
        this.state = {
          modalIsOpen: false,
          parameters:this.props.parameters
       
        };
        console.log(this.props)
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }
     
      openModal() {
        this.setState({modalIsOpen: true});
      }
     
      afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#333333';
      }
     
      closeModal() {
        this.setState({modalIsOpen: false});
      }

      handleChange(e){
          if(e.target.checked){
              document.getElementById('valInput').classList.add('d-none');
              document.getElementById('multiparam').classList.remove('d-none');
             
          }else{
            document.getElementById('multiparam').classList.add('d-none');
            document.getElementById('valInput').classList.remove('d-none');
          }
          alert(JSON.stringify(this.state.parameters))

      }
     
      validateParamName(e) {
        document
            .getElementById('paramId')
            .classList
            .remove("error");
        this.setState({errorMessage: ""});
        var flag=false;
        if (this.state.parameters) {
            this
                .state
                .parameters
                .map(function (val, ind) {
                    if (val.name === e) {
                        document
                            .getElementById('paramId')
                            .classList
                            .add("error");
                        document
                            .getElementById('paramId')
                            .focus();
                        flag= true;

                    }
                })
        }
        if (flag) {
            this.setState({errorMessage: "Please enter valid Parameter name", successMessage: ""});
        }
    }

      render() {
        return (
          <div>
            <button onClick={this.openModal} className="login-link-btn">
            Add Parameter</button>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
     
              <h2 ref={subtitle => this.subtitle = subtitle}>Add new Parameter</h2>
              <hr/>
              <div id="addparam" >
              <div className="row row-popup">
              <label><input type="checkbox" value="" onChange={(e)=>this.handleChange(e)}/>Multivalue</label>
              </div>
            
              <div className="row row-popup">
                <div class="col-lg-6 col-md-6 col-sm-6 "><label className="digi-label">Name
                </label></div>
                <div class="col-lg-6 col-md-6 col-sm-6 ">
                <label className="digi-label">Value</label>
                </div>
                </div>
                <hr/>
               <div className="row row-popup">
               <div class="col-lg-6 col-md-6 col-sm-6 ">
              <input
                  className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                  name="paramId"
                  id="paramId"
                  onBlur={(e) => this.validateParamName(e.target.value)}></input>
                  </div>
            <div class="col-lg-6 col-md-6 col-sm-6 ">
              <span id="valInput">
              <input
                  className="form-control digi-textbox-td digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td-popup"
                  name="valId"
                  id="valId">
            </input>
            </span>
            <span  id="multivalInput" className="d-none">
            </span>
            </div>
            </div>
            <div id="multiparam" class="d-none">
            <label className="digi-label">Multivalues</label>
            <div className="row row-popup">
            <div class="col-lg-6 col-md-6 col-sm-6 ">
            <input
                  className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                  name="multiParamId"
                  id="multiParamId"
                  ></input>
                  </div>
             <div class="col-lg-6 col-md-6 col-sm-6 ">
              <input
                  className="form-control digi-textbox-td digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td-popup"
                  name="multivalId"
                  id="multivalId">
            </input>
            </div>
            </div>
            </div>
           

            </div>
            <div className="row">
            <span><button className="login-link-btn login-link-small">Save</button></span>
            <span><button onClick={this.closeModal}  className="login-link-btn login-link-small">cancel</button></span>
            </div>
            </Modal>
          </div>
        );
      }
}
export default Paramform;