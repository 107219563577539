import React, {Component} from 'react';
import EditableLabel from 'react-inline-edition';
import axios from "axios";
import Menu from '../Menu';
import queryString from 'query-string'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class ChannelDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            id: "",
            channel: "",
            channelName: "",
            parameters: [ 
            ],
            defaultParams: [],
            newParamval: [],
            successMessage: "",
            errorMessage:"",
            modalEditIsOpen:false,
            multivalueParam: [],
            editParam:""

        };
        this.dataChanged = this
            .dataChanged
            .bind(this);
        this.renderParamTable = this
            .renderParamTable
            .bind(this);
        this.openEditModal = this
            .openEditModal
            .bind(this);
        this.afterEditOpenModal = this
            .afterEditOpenModal
            .bind(this);
        this.closeEditModal = this
            .closeEditModal
            .bind(this);
}


displayEdit(value,idx){
    if(value.multiValue){
        var param=[];
        
        if(value.value==""){
            return ( <div className="icon-pointer" onClick={(e)=>this.openEditModal(param,value.name)}>
            <span className="edit-multi">Click To Edit </span>
          </div>)
        }else{
            param = value.value;
            return ( <div className="icon-pointer" onClick={(e)=>this.openEditModal(param,value.name)}>
            {param.map((key,id)=><span className="edit-multi"> {key.name}:{key.value} </span>)} 
          </div>)
        }
        
    }else{
        return (<EditableLabel
        text={value.value}
        labelClassName='myLabelClass'
        inputClassName='form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey'
        inputWidth='200px'
        inputHeight='25px'
        inputMaxLength='50'
        onFocusOut={(e) => this.dataChanged(e, idx)}
        type="input"/>)
    }
  }
    openEditModal(value,paramName) {
        var newParam=JSON.parse(JSON.stringify(value));
        this.setState({modalEditIsOpen: true,multivalueParam:newParam,editParam:paramName,errorMessage:""});
    }

    afterEditOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#333333';
    }

    closeEditModal() {
        this.setState({modalEditIsOpen: false, errorMessage: "",});
    }
    componentDidMount() {
        this.getChannelDetail();
    }

    validateMultiParamName(e,fieldName) {
        document
            .getElementById(fieldName)
            .classList
            .remove("error");
        this.setState({errorMessage: ""});
        var idx = document.getElementById("editIdx").value;
        var flag = false;
        if (this.state.multivalueParam) {
            this
                .state
                .multivalueParam
                .map(function (val, ind) {
                    if (val.name === e && idx!=ind) {
                        document
                            .getElementById(fieldName)
                            .classList
                            .add("error");
                        document
                            .getElementById(fieldName)
                            .focus();
                        flag = true;

                    }
                })
        }
        if (flag) {
            this.setState({errorMessage: "Please enter valid Parameter name", successMessage: ""});
        }
    }


    editmultiValueMap() {
        var name = document.getElementById("editmultiparamId");
        var value = document.getElementById("editmultivalId");
        var idx = document.getElementById("editIdx");
        if (!name.value || !value.value) {
            alert("field can not be empty");
            return;
        }
        const newobj = {
            name: name.value,
            value: value.value
        }
        if(idx.value===""){
            this
            .state
            .multivalueParam.push(newobj);
        }else{
            this
                .state
                .multivalueParam
                .map(function (val, ind) {
                    if(ind==idx.value){
                       val.name= name.value;
                       val.value = value.value;
                        return;
                    }
                });
        }
        this.setState({multivalueParam: this.state.multivalueParam})
        name.value = "";
        value.value = "";
        idx.value="";
    }
    
    saveMultiParameter = (e) => {
        var name = document
            .getElementById("multi-paramId")
            .value;
        if (!name || this.state.multivalueParam.length===0) {
            alert("field can not be empty");
            return;
        }
        const newobj = {
            name: name,
            multiValue: true,
            value: this.state.multivalueParam
        }
        var newParam = this.state.multivalueParam;
        this.state
                .parameters
                .map(function (val, ind) {
                    if(val.name==name){
                       val.value = newParam;
                        return;
                    }
                });
        
       
        const obj = {
            serviceName: this.state.channel.msname,
            params: JSON.stringify(this
                .state
                .parameters),
            channelName: this.state.channel.channel
          
        };
        axios
            .post('/api/channel-config', obj)
            .then(res => {
                this.setState({multivalueParam:[],parameters: this
                    .state
                    .parameters, successMessage: "Parameter is added successfully", errorMessage: ""});
                this.closeEditModal();
            })
            .catch(function (error) {
                console.log(error);
            })

    }

    editMultiParameter(idx) {
        document.getElementById("editmultiparamId").value= this.state.multivalueParam[idx].name
        document.getElementById("editmultivalId").value=this.state.multivalueParam[idx].value
        document.getElementById("editIdx").value=idx
    }


    editParameter(e, idx) {
        var value = this.state.newParamval[idx];
        if (!value) {
            return;
        }
        this.state.parameters[idx].value = value;
        const obj = {
            serviceName: this.state.channel.msname,
            params: JSON.stringify(this.state.parameters),
            channelName: this.state.channel.channel
        };
        axios
            .post('/api/channel-config', obj)
            .then(res => {
                this.setState({parameters: this.state.parameters, successMessage: "Parameter is updated successfully", errorMessage: ""});
                document
                    .getElementById(idx)
                    .classList
                    .remove("green-color");
                this.state.newParamval[idx] = "";
            })
            .catch(function (error) {
                console.log(error);
            })

    }

    publish(e) {
        const obj = {
            msname: this.state.channel.msname,
            channelName: this.state.channel.channel
        };
        const self = this;
        axios
            .post('/api/publish', obj)
            .then(res => {
                var body = JSON.parse(res.data.data);
                if(body.status && body.status==='success'){
                this.setState({successMessage: "Data has been published successfully", errorMessage: ""});
                }else{
                    this.setState({successMessage: "", errorMessage: "Unable to publish the data. Due to "+body.messa});
               
                }
            })
            .catch(function (error) {
                console.log(error);
                self.setState({successMessage: "", errorMessage: "Unable to publish the data"});
            })

    }

    getChannelDetail() {
        const values = queryString.parse(this.props.location.search);
        axios
            .get('/api/channel', {
            params: {
                authtoken: this.state.token,
                channelName: this.props.match.params.id,
                serviceName: values.msname
            }
        })
            .then(res => this.setState({channel: res.data.data[0], parameters: res.data.data[0].configdata}))
            .catch(function (error) {
                console.log(error);
            })

    }
    
    dataChanged(e, idx) {
        if (this.state.parameters[idx].value !== e) {
            document
                .getElementById(idx)
                .classList
                .add("green-color");
            this.state.newParamval[idx] = e;
            console.log(this.state.newParamval);
        } else {
            document
                .getElementById(idx)
                .classList
                .remove("green-color");
        }
    }

    deleteMultiParameter(idx) {
        this.state.multivalueParam.splice(idx, 1);
           this.setState({multivalueParam:this.state.multivalueParam})
   
       }
      
    renderParamTable() {
        if (this.state.parameters) {
        return this
                .state
                .parameters
                .map((val, idx) => {
                    return (
                        <tr key={idx}>
                            <td>
                                <label className="digi-label">{val.name}</label>
                            </td>
                           
                            <td>
                            {this.displayEdit(val,idx)}
                            </td>
                            <td>
                                <span
                                    id={idx}
                                    class="glyphicon glyphicon-ok icon-pointer service-list-action"
                                    onClick={(e) => this.editParameter(e, idx)}></span>
                            </td>
                        </tr>
                    )
                });

        } else {
            return <tr>
                <td>No records</td>
            </tr>;
        }
    }

   
    render() {
        return (
            <div>
                <Menu/>
                <div>
                    <div className="service-form">
                        <h2>Channel Detail</h2>
                        <hr/>
                        <div id="result">
                            <div className="field-wrap">
                                <label htmlFor="channelName" className="digi-label">Channel Name:</label>
                                <label className="digi-label">{this.state.channel.channel}</label>
                            </div>
                            <div className="field-wrap">
                                <label htmlFor="msname" className="digi-label">
                                    Microservice Name:
                                </label>
                                <label className="digi-label">{this.state.channel.msname}</label>
                            </div>

                            <h4>Configuration Parameters</h4>
                            <hr/> {this.state.successMessage && <div class="isa_success">
                                <span class="glyphicon glyphicon-check icon-pointer service-list-action"></span>
                                {this.state.successMessage}
                            </div>

                            }
                            {this.state.errorMessage && <div class="isa_error">
                            <span class="glyphicon glyphicon-remove icon-pointer service-list-action"></span>
                            {this.state.errorMessage}
                        </div>
                        }
                            <table className="table-gray">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Value</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderParamTable()}
                                </tbody>
                            </table>
                        </div>
                     </div>
                </div>
                <Modal
                isOpen={this.state.modalEditIsOpen}
                onAfterOpen={this.afterEditOpenModal}
                onRequestClose={this.closeEditModal}
                style={customStyles}
                contentLabel="Example Modal">

                <h2 class="popup-header" ref={subtitle => this.subtitle = subtitle}>Edit Multi-value Parameter</h2>
                <hr/> {this.state.errorMessage && <div class="isa_error">
                    <span class="glyphicon glyphicon-remove icon-pointer service-list-action"></span>
                    {this.state.errorMessage}
                </div>
}
                <div id="editparam">
                    <div id="multi-edit-form" >
                    <div className="row row-popup">
                        <div class="col-lg-6 col-md-6 col-sm-6 ">
                            <label className="digi-label"><b>Name</b>
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 ">
                            <label className="digi-label"><b>Value</b></label>
                        </div>
                    </div>
                    <hr className="hr-hd"/>
                        <div className="row row-popup">
                            <div class="col-lg-6 col-md-6 col-sm-6 ">
                                <input
                                    className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                                    name="multi-paramId"
                                    id="multi-paramId"
                                    value={this.state.editParam} readOnly></input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 ">
                                <span id="multivalInput">
                                    <ul className="ul-text">{this
                                            .state
                                            .multivalueParam
                                            .map((val, idx) => 
                                                <div>
                                                <span class="service-list-action">{val.name}:{val.value}
                                                </span>
                                                <span class="glyphicon glyphicon-edit icon-pointer service-list-action " onClick={(e)=>this.editMultiParameter(idx)}>
                                                </span>
                                                <span class="glyphicon glyphicon-remove icon-pointer" onClick={(e)=>this.deleteMultiParameter(idx)}>
                                                </span>
                                            </div>
                                        )}</ul>
                                </span>
                            </div>
                            </div>
                            <div>
                                <label className="digi-label"><b>Multi-value</b></label>
                                <div className="row row-popup">
                                    <div class="col-lg-5 ">
                                        <input
                                            className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                                            name="editmultiparamId"
                                            id="editmultiparamId"
                                            onBlur={(e) => this.validateMultiParamName(e.target.value,'editmultiparamId')} ></input>
                                    </div>
                                    <div class="col-lg-5 ">
                                        <input
                                            className="form-control digi-textbox-td digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td-popup"
                                            name="editmultivalId"
                                            id="editmultivalId"></input>
                                        <input id="editIdx" type="hidden" value=""></input>
                                    </div>
                                    <div class="col-lg-2">
                                        <span
                                            class="icon-popup glyphicon glyphicon-plus icon-pointer service-list-action"
                                            onClick={(e) => this.editmultiValueMap()}></span>
                                    </div>
                                </div>
                            

                        </div>
                        <div className="row">
                            <span>
                                <button
                                    onClick={this.saveMultiParameter}
                                    className="login-link-btn login-link-small">Save</button>
                            </span>
                            <span>
                                <button onClick={this.closeEditModal} className="login-link-btn login-link-small">cancel</button>
                            </span>
                        </div>

                    </div>
                    </div>
            </Modal>
       

            </div>
        );
    }
}

export default ChannelDetail;
