import React,{ Component }  from 'react';
import SideNav, {NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';


class Menu extends Component{
    constructor(props) {
        super(props);
        this.state = {
            keycloak:this.props.keycloak
        };
    }

   

   
    render() {
        return (
            <SideNav onSelect={this.onSelect} onToggle={this.onToggle}>
            <SideNav.Toggle />
            <SideNav.Nav  onSelect={(selected) => {
                window.location.href=selected;
            }}>
                    <NavItem eventKey="microservice">
                    <NavIcon>
                    <span class="glyphicon glyphicon-cog icon-pointer menu-icon"></span>
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="Microservice">
                    Microserivce
                    </NavText>
                    <NavItem eventKey="/microservice/add-service">
                        <NavText title="add service">
                            Add Service
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="/microservice/list-service">
                        <NavText title="list service">
                            List
                        </NavText>
                    </NavItem>
                </NavItem>
        
                <NavItem eventKey="Channel">
                    <NavIcon>
                    <span class="glyphicon glyphicon-list-alt icon-pointer menu-icon"></span>
                    </NavIcon>
                    <NavText style={{ paddingRight: 32 }} title="Channel">
                    Channel
                    </NavText>
                    <NavItem eventKey="/channel/add-channel">
                        <NavText title="Channel config">
                            Channel Configuration
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="/channel/list-channel">
                        <NavText title="channel list">
                            List
                        </NavText>
                    </NavItem>
                </NavItem>
                <NavItem eventKey="/logout">
                            <NavIcon>
                            <span className="glyphicon glyphicon-log-out icon-pointer menu-icon"></span>
                            </NavIcon>
                            <NavText  style={{ paddingRight: 32 }} title="Sign out" >
                                Sign out
                            </NavText>
                        </NavItem>
            </SideNav.Nav>
        </SideNav>
        
        );
    }
}
export default Menu;