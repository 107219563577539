import React,{ Component }  from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import Menu from '../Menu';
import { thistle } from 'color-name';
class ChannelList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            id: "",
            channels:[],
            successMessage:"",
            errorMessage:"",
            errorList:[]
        };
    }
     componentDidMount(){
        this.getAllChannels();
    }

    getAllChannels(){
       axios.get('/api/all-channel', {
            params: {
                authtoken:this.state.token,
            }
          })
          .then(res=>this.setState({channels:res.data.data.rows}))
          .catch(function (error) {
            console.log(error);
          })
         

    }

    deleteChannel(channel,service,idx){
        var obj={
            channel:channel,
            serviceName:service
        }
        axios.post('/api/channel-delete',obj)
        .then(res =>{
           if(res.data.data=='success'){
            this.state.channels.splice(idx,1);
            this.setState({Channels:this.state.channels,errorMessage:"Channel Deleted Successfully"})
          }
        })
        .catch(function (error) {
          console.log(error);
        })

    }

    publish(e) {
        const self = this;
        axios
            .post('/api/publishall')
            .then(res => {
                if(res.data.data.status && res.data.data.status==='success'){
                this.setState({successMessage: "All data has been published successfully", errorMessage: "",errorList:[]});
                }else{
                    this.setState({successMessage: "", errorMessage: "Unable to publish the data.",errorList:res.data.data.message});
                }
            })
            .catch(function (error) {
                console.log("error")
                self.setState({successMessage: "", errorMessage: "Unable to publish the data"});
            })

    }


    render() {
        return (
            <div className="service-form">
            <Menu/>
            <div id="result">
                <div className="row row-popup">
                <div class="col-lg-9">
                <h2>Channel List</h2>
                </div>
                <div class="col-lg-3">
                </div>
                </div>
                <hr className="hr-hd"></hr>
                {this.state.errorMessage && <div class="isa_error">
                <span class="glyphicon glyphicon-remove icon-pointer service-list-action"></span>
                {this.state.errorMessage}
                <ul>
                {this.state.errorList.map(function(d, idx){
                    return (<li key={idx}>{d}</li>)
                  })}</ul>
                </div>}
                {this.state.successMessage && <div class="isa_success">
                    <span class="glyphicon glyphicon-check icon-pointer service-list-action"></span>
                    {this.state.successMessage}
                    </div>

                }
                <table  className="table-gray">
                <thead>
                <tr>
                <th>Channel Name</th>
                <th>Microservice Name</th>
                <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {this.state.channels.map((channel,idx) => (
                    <tr key={idx}>
                        <td> <div className="padding-td">{channel.channel}</div></td>
                        <td><div className="padding-td">{channel.msname}</div></td>  
                       <td > <div className="padding-td">
                      <span> <Link to={"/channel/edit-channel/"+channel.channel+"?msname="+channel.msname} className="glyphicon glyphicon-edit icon-pointer service-a"></Link>
                      </span>
                      <span
                      class="glyphicon glyphicon-trash icon-pointer service-list-action"
                      onClick={(e) => {
                      if (window.confirm('Are you sure to delete this Channel Configuration?')) {
                          this.deleteChannel(channel.channel,channel.msname,idx)
                      }
                  }}></span></div></td>
                    </tr>
                ))}
                {this.state.channels && this.state.channels.length===0?<td>No Data</td>:""}
                      
                </tbody>
                </table>
            </div>
            </div>
        );
    }
}
export default ChannelList;