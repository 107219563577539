import React, {Component} from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import Menu from '../Menu';
class ServiceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            id: "",
            services: []
        };
     }
    componentDidMount() {
        this.getAllServices();
    }

    getAllServices() {
        axios
            .get('/api/all-service', {
            params: {
                authtoken: this.state.token
            }
        })
            .then(res => this.setState({services: res.data.data}))
            .catch(function (error) {
                console.log(error);
            })

    }

    render() {
        return (
            <div className="service-form">
                <Menu/>
                <div id="result">
                    <h2>Microservice List</h2>
                    <hr></hr>
                    <table className="table-gray">
                        <thead>
                            <tr>
                                <th>Microservice Name</th>
                                <th>Endpoint</th>
                                <th>Roles</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this
                                .state
                                .services
                                .map((service, serviceIndex) => (
                                    <tr key={serviceIndex}>

                                        <td>
                                            <div>{service.msname}</div>
                                        </td>
                                        <td>
                                            <div>{service.endpoint}</div>
                                        </td>
                                        <td>
                                            <div>{service.roles}</div>
                                        </td>
                                        <td>
                                            <div>
                                                <Link
                                                    to={"/microservice/edit-service/" + service.msname}
                                                    className="glyphicon glyphicon-edit icon-pointer service-a"></Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {this.state.services && this.state.services.length===0?<td>No Data</td>:""}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
export default ServiceList;