import React from "react";
import '../custom.css';
import axios from "axios";
import Select from 'react-select';
import Menu from "../Menu";
import Modal from 'react-modal';
import EditableLabel from 'react-inline-edition';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


class Channelform extends  React.Component{
    constructor(props) {
        super(props);
    this.state={
        channelName:"",
        serviceName:"",
        parameters:"",
        selectedOption:null,
        serviceList:[],
        defaultParam:[],
        actualParam:[],
        errorMessage:"",
        disableBtn:true,
        modalEditIsOpen:false,
        multivalueParam: [],
        editParam:""
    }
    this.openEditModal = this
    .openEditModal
    .bind(this);
    this.afterEditOpenModal = this
    .afterEditOpenModal
    .bind(this);
    this.closeEditModal = this
    .closeEditModal
    .bind(this);
    }
    openEditModal(value,paramName) {
        var newParam=JSON.parse(JSON.stringify(value));
        if(newParam==""){
            newParam=[];
        }
        this.setState({modalEditIsOpen: true,multivalueParam:newParam,editParam:paramName});
    }

    afterEditOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#333333';
    }

    closeEditModal() {
        this.setState({modalEditIsOpen: false,multivalueParam:[], errorMessage: "",});
    }
    validateMultiParamName(e,fieldName) {
        document
            .getElementById(fieldName)
            .classList
            .remove("error");
        this.setState({errorMessage: ""});
        var flag = false;
        if (this.state.multivalueParam) {
            this
                .state
                .multivalueParam
                .map(function (val, ind) {
                    if (val.name === e) {
                        document
                            .getElementById(fieldName)
                            .classList
                            .add("error");
                        document
                            .getElementById(fieldName)
                            .focus();
                        flag = true;

                    }
                })
        }
        if (flag) {
            this.setState({errorMessage: "Please enter valid Parameter name", successMessage: ""});
        }
    }

    componentDidMount(){
        this.getAllServicesName();
    }

    deleteMultiParameter(idx) {
        
        this.state.multivalueParam.splice(idx, 1);
           this.setState({multivalueParam:this.state.multivalueParam})
   
       }
    

    getAllServicesName(){
        axios.get('/api/all-services-name', {
            params: {
                authtoken:this.state.token,
            }
          })
          .then(res=>{
              if(res.data.data.length>0){
                res.data.data.map((val,idx)=>{
                    var obj ={
                        value:val.msname,
                        label:val.msname
                    }
                    this.state.serviceList.push(obj);  
                });
              }
             
        })
          .catch(function (error) {
            console.log(error);
          })
         

    }
    updateParam(value,idx){
        this.state.actualParam[idx].value=value;
     }

    handleSubmit = (event) => { 
        event.preventDefault();
        this.state.actualParam.map((obj,idx) =>{
            if(obj.value===""){
                this.state.actualParam[idx].value=this.state.defaultParam[idx].value;
            }
        }
        );
        const obj = {
             serviceName: this.state.serviceName,
             channelName:this.state.channelName,
             configdata:JSON.stringify(this.state.defaultParam)
            };
        axios.post('/api/add-channel',obj)
          .then(window.location.href="/channel/list-channel")
          .catch(function (error) {
            console.log(error);
          })
    }

    editmultiValueMap() {
        var name = document.getElementById("editmultiparamId");
        var value = document.getElementById("editmultivalId");
        var idx = document.getElementById("editIdx");
        if (!name.value || !value.value) {
            alert("field can not be empty");
            return;
        }
        const newobj = {
            name: name.value,
            value: value.value
        }
        if(idx.value===""){
            this
            .state
            .multivalueParam.push(newobj);
        }else{
            this
                .state
                .multivalueParam
                .map(function (val, ind) {
                    if(ind==idx.value){
                       val.name= name.value;
                       val.value = value.value;
                        return;
                    }
                });
        }
        this.setState({multivalueParam: this.state.multivalueParam})
        name.value = "";
        value.value = "";
        idx.value="";
    }
    
    saveMultiParameter = (e) => {
        var name = document
            .getElementById("multi-paramId")
            .value;
        if (!name || this.state.multivalueParam.length===0) {
            alert("field can not be empty");
            return;
        }
        var newParam = this.state.multivalueParam;
        this.state
                .defaultParam
                .map(function (val, ind) {
                    if(val.name==name){
                       val.value = newParam;
                        return;
                    }
                });
        this.setState({defaultParam:this.state.defaultParam});
        this.closeEditModal()
    }

    dataChanged (e,idx) {
        this.state.defaultParam[idx].value=e;
        this.setState({defaultParam:this.state.defaultParam});
    }

    editMultiParameter(idx) {
        document.getElementById("editmultiparamId").value= this.state.multivalueParam[idx].name
        document.getElementById("editmultivalId").value=this.state.multivalueParam[idx].value
        document.getElementById("editIdx").value=idx
    }


    getServiceParam(serviceName){
        axios.get('/api/service',{
            params:{
                authtoken:this.state.token,
                serviceName:serviceName
            }
        })
          .then( res=>{
              return res.data.data.rows;
          })
          .catch(function (error) {
            console.log(error);
          })
    }
   
     isValidmsname = async (event) => { 
      if(this.state.channelName && event.value){
            axios.get('/api/channel',{
                params:{
                    authtoken:this.state.token,
                    serviceName: event.value,
                    channelName:this.state.channelName
                }
            })
              .then( res=>{
                  if(res.data.data.length>0){
                    document.getElementById('channel-grid').className="table-gray d-none th-two-col";
                    this.setState({selectedOption:"",disableBtn:true,errorMessage:"Channel and Microservice combination already exist"});
                  }else{
                    axios.get('/api/service',{
                        params:{
                            authtoken:this.state.token,
                            serviceName:event.value
                        }
                    })
                      .then( res=>{
                          if(res.data.data.length){
                            document.getElementById('channel-grid').className="table-gray show th-two-col"
                            this.setState({disableBtn:false,errorMessage:"",defaultParam:res.data.data[0].params})
                          }
                      })
                      .catch(function (error) {
                        console.log(error);
                      })
                  }
                  })
              .catch(function (error) {
                console.log(error);
              })
        }
        
    }
    handleChange = (e) => {
       if(e.target.name){
        this.setState({ [e.target.name]: e.target.value })
       }
          
      }
    
      handleSelectboxChange = selectedOption => {
        this.setState({ selectedOption:selectedOption,serviceName:selectedOption.value });
        this.isValidmsname(selectedOption);
      };

      displayEdit(value,idx){
        if(value.multiValue){
            var param=[];
            
            if(value.value==""){
                return ( <div className="icon-pointer" onClick={(e)=>this.openEditModal(param,value.name)}>
                <span className="edit-multi">Click To Edit </span>
              </div>)
            }else{
                param = value.value;
                return ( <div className="icon-pointer" onClick={(e)=>this.openEditModal(param,value.name)}>
                {param.map((key,id)=><span className="edit-multi"> {key.name}:{key.value} </span>)} 
              </div>)
            }
            
        }else{
            return (<EditableLabel
            text={value.value}
            labelClassName='myLabelClass'
            inputClass='form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey'
            inputWidth='200px'
            inputHeight='25px'
            inputMaxLength='50'
            onFocusOut={(e) => this.dataChanged(e, idx)}
            type="input"/>)
        }
      }
    render(){
        let {selectedOption,channelName} =this.state
        return(
            <div>
            <Menu/>
            
            <div className="service-form">
            <h2>Channel Form</h2>
            <hr/>
            {this.state.errorMessage && <div class="isa_error">
                <span class="glyphicon glyphicon-remove icon-pointer service-list-action"></span>
                {this.state.errorMessage}
                </div>}
            <form onSubmit={this.handleSubmit} onChange={this.handleChange} >
              
                <div className="field-wrap">
                <label htmlFor="channelName" className="digi-label">Channel Name:</label>
                <input type="text" onBlur={this.isValidmsname} name="channelName" id="channelName" value={channelName} className="digi-btn-rounded digi-textbox has-feedback placeholdergrey"></input>
                </div>
                <div className="field-wrap">
                <label htmlFor="serviceName" className="digi-label"> Service List: </label>
                <Select className="channel-select" name="serviceName" id="serviceName" onChange={this.handleSelectboxChange} value={selectedOption} options={this.state.serviceList} ></Select>
                </div>
                <table id="channel-grid" className="table-gray d-none th-two-col">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.defaultParam.map((val,idx)=>
                        <tr key={idx}>
                        <td className="channel-form-td">{val.name}</td>
                        <td className="channel-form-td">
                        {this.displayEdit(val,idx)}
                        </td>
                        </tr>)}
                    </tbody>
                   </table>
                <input type="submit" value="Submit" disabled={this.state.disableBtn} className="btn btn-primary digi-login-btn"/>
            </form>
            </div>
            <Modal
                isOpen={this.state.modalEditIsOpen}
                onAfterOpen={this.afterEditOpenModal}
                onRequestClose={this.closeEditModal}
                style={customStyles}
                contentLabel="Example Modal">

                <h2 class="popup-header" ref={subtitle => this.subtitle = subtitle}>Edit Multi-value Parameter</h2>
                <hr/> {this.state.errorMessage && <div class="isa_error">
                    <span class="glyphicon glyphicon-remove icon-pointer service-list-action"></span>
                    {this.state.errorMessage}
                </div>
}
                <div id="editparam">
                    <div id="multi-edit-form" >
                    <div className="row row-popup">
                        <div class="col-lg-6 col-md-6 col-sm-6 ">
                            <label className="digi-label"><b>Name</b>
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 ">
                            <label className="digi-label"><b>Value</b></label>
                        </div>
                    </div>
                    <hr className="hr-hd"/>
                        <div className="row row-popup">
                            <div class="col-lg-6 col-md-6 col-sm-6 ">
                                <input
                                    className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                                    name="multi-paramId"
                                    id="multi-paramId"
                                    value={this.state.editParam} readOnly></input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 ">
                                <span id="multivalInput">
                                    <ul className="ul-text">{this
                                            .state
                                            .multivalueParam
                                            .map((val, idx) => 
                                                <div>
                                                <span class="service-list-action">{val.name}:{val.value}
                                                </span>
                                                <span class="glyphicon glyphicon-edit icon-pointer service-list-action " onClick={(e)=>this.editMultiParameter(idx)}>
                                                </span>
                                                <span class="glyphicon glyphicon-remove icon-pointer" onClick={(e)=>this.deleteMultiParameter(idx)}>
                                                </span>
                                            </div>
                                        )}</ul>
                                </span>
                            </div>
                            </div>
                            <div>
                                <label className="digi-label"><b>Multi-value</b></label>
                                <div className="row row-popup">
                                    <div class="col-lg-5 ">
                                        <input
                                            className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                                            name="editmultiparamId"
                                            id="editmultiparamId"
                                            onBlur={(e) => this.validateMultiParamName(e.target.value,'editmultiparamId')} ></input>
                                    </div>
                                    <div class="col-lg-5 ">
                                        <input
                                            className="form-control digi-textbox-td digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td-popup"
                                            name="editmultivalId"
                                            id="editmultivalId"></input>
                                        <input id="editIdx" type="hidden" value=""></input>
                                    </div>
                                    <div class="col-lg-2">
                                        <span
                                            class="icon-popup glyphicon glyphicon-plus icon-pointer service-list-action"
                                            onClick={(e) => this.editmultiValueMap()}></span>
                                    </div>
                                </div>
                            

                        </div>
                        <div className="row">
                            <span>
                                <button
                                    onClick={this.saveMultiParameter}
                                    className="login-link-btn login-link-small">Save</button>
                            </span>
                            <span>
                                <button onClick={this.closeEditModal} className="login-link-btn login-link-small">cancel</button>
                            </span>
                        </div>

                    </div>
                    </div>
            </Modal>
       
</div>
            
        )
    }
}
export default Channelform;