import React, {Component} from 'react';
import EditableLabel from 'react-inline-edition';
import axios from "axios";
import Menu from '../Menu';
import Paramform from '../popup/Paramform';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class ServiceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service: "",
            servicename: "",
            endPoint: "",
            roles: "",
            parameters: [],
            errorMessage: "",
            successMessage: "",
            newParamval: [],
            modalIsOpen: false,
            modalEditIsOpen: false,
            multivalueParam: [],
            editParam:""
        };
        this.saveParameter = this
            .saveParameter
            .bind(this);
        this.openModal = this
            .openModal
            .bind(this);
        this.afterOpenModal = this
            .afterOpenModal
            .bind(this);
        this.closeModal = this
            .closeModal
            .bind(this);
    }

    componentDidMount() {
        this.getServiceDetail();
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#333333';
    }

    closeModal() {
        this.setState({modalIsOpen: false, errorMessage: "",multivalueParam:[]});
    }


    displayFlag(idx) {
        var flag ;
       if(this.state.parameters[idx].multiValue){
        flag = true
       } 
       return (<div class="custom-control custom-checkbox">
       <input
           type="checkbox"
           class="custom-control-input"
           id="multicheck-td"
           checked={flag}
           disabled
           />
       <label class="custom-control-label popup-sub" for="multicheck-td"></label>
   </div>)
    }

    
    getServiceDetail() {
        axios
            .get('/api/service', {
            params: {
                authtoken: this.state.token,
                serviceName: this.props.match.params.id
            }
        })
            .then(res => this.setState({service: res.data.data[0], serviceName: res.data.data.msname, endPoint: res.data.data[0].endpoint, roles: res.data.data[0].roles, parameters: res.data.data[0].params}))
            .catch(function (error) {
                console.log(error);
            })

    }
    endpointChanged(object, e) {
        if (e) {
            const obj = {
                serviceName: this.state.service.msname,
                roles: this.state.roles,
                endpoint: e
            };
            this.updateValue(obj);
        } else {
            this.setState({roles: this.state.service.endpoint})
        }
    }
    roleChanged(object, e) {
        if (e) {
            const obj = {
                serviceName: this.state.service.msname,
                roles: e,
                endpoint: this.state.endPoint
            };
            this.updateValue(obj);
        } else {
            this.setState({roles: this.state.service.roles})
        }
    }
   
    updateValue(obj) {
        axios
            .post('/api/edit-service', obj)
            .then(res => {
                this.setState({endPoint: obj.endpoint, roles: obj.roles});
            })
            .catch(function (error) {
                console.log(error);
            })

    }

    
    saveParameter = (e) => {
        var name = document
            .getElementById("paramId")
            .value;
          
        if (!name) {
            alert("field can not be empty");
            return;
        }
        if(this.validateParamName(name,'paramId')){
            return;
        }
        const newobj = {
            name: name,
            value: "",
            multiValue:document
            .getElementById("multicheck")
            .checked
        }
        var newParam = this.state.parameters;
        newParam.push(newobj);
        const obj = {
            serviceName: this.state.service.msname,
            params: JSON.stringify(newParam),
            flag:true
        };
        axios
            .post('/api/edit-param', obj)
            .then(res => {
                this.setState({parameters: newParam,multivalueParam:[], successMessage: "Parameter is added successfully", errorMessage: ""});
                //this.removeParameter(e);
                this.closeModal();
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    deleteParameter(e, idx) {
        var newParam = this.state.parameters;
        newParam.splice(idx, 1);
        const obj = {
            serviceName: this.state.service.msname,
            params: JSON.stringify(newParam),
            flag:true
        };
        axios
            .post('/api/edit-param', obj)
            .then(res => {
                this.setState({parameters: newParam, successMessage: "Parameter is deleted successfully", errorMessage: ""});
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    
    validateParamName(e,fieldName) {
        document
            .getElementById(fieldName)
            .classList
            .remove("error");
        this.setState({errorMessage: ""});
        var flag = false;
        if (this.state.parameters) {
            this
                .state
                .parameters
                .map(function (val, ind) {
                    if (val.name === e) {
                        document
                            .getElementById(fieldName)
                            .classList
                            .add("error");
                        document
                            .getElementById(fieldName)
                            .focus();
                        flag = true;

                    }
                })
        }
        if (flag) {
            this.setState({errorMessage: "Please enter valid Parameter name", successMessage: ""});
        }
        return flag;
    }

    render() {
        return (
            <div>
                <Menu/>
                <div>
                    <div className="service-form">
                        <h2>Microservice Detail</h2>
                        <hr></hr>
                        <div id="result">
                            <div className="field-wrap">
                                <label htmlFor="serviceName" className="digi-label">Service Name:</label>
                                <label htmlFor="serviceName" className="digi-label">{this.state.service.msname}</label>
                            </div>
                            <div className="field-wrap">
                                <label htmlFor="endPoint" className="digi-label">
                                    End Point:
                                </label>
                                <EditableLabel
                                    text={this.state.endPoint}
                                    labelClassName='form-control digi-btn-rounded digi-label has-feedback placeholdergrey'
                                    inputClassName='form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey'
                                    inputWidth='200px'
                                    inputHeight='25px'
                                    inputMaxLength='50'
                                    name="endpoint"
                                    onFocusOut={(e) => this.endpointChanged(this, e)}/>
                            </div>
                            <div className="field-wrap">
                                <label htmlFor="roles" className="digi-label">
                                    Roles:
                                </label>
                                <EditableLabel
                                    text={this.state.roles}
                                    labelClassName='myLabelClass'
                                    inputClassName='form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey'
                                    inputWidth='200px'
                                    inputHeight='25px'
                                    inputMaxLength='50'
                                    onFocusOut={(e) => this.roleChanged(this, e)}/>
                            </div>
                            <h4>Parameters</h4>
                            <hr/> {this.state.successMessage && <div class="isa_success">
                                <span class="glyphicon glyphicon-check icon-pointer service-list-action"></span>
                                {this.state.successMessage}
                            </div>
}
                            <div>
                                <button onClick={this.openModal} className="login-link-btn">
                                    Add Parameter</button>
                            </div>
                            <br/>
                            <table className="table-gray">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Multi-value Flag</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.parameters && this
                                        .state
                                        .parameters
                                        .map((val, idx) => <tr key={idx}>
                                            <td>
                                                <label className="digi-label">{val.name}</label>
                                            </td>
                                            <td>
                                                {this.displayFlag(idx)}
                                            </td>
                                            <td>
                                                {val.name!=='x-api-key' &&
                                                <span
                                                    class="glyphicon glyphicon-trash icon-pointer service-list-action"
                                                    onClick={(e) => {
                                                    if (window.confirm('Are you sure to delete this parameter?')) {
                                                        this.deleteParameter(e, idx)
                                                    }
                                                }}></span>}
                                            </td>
                                        </tr>)}
                                    {this.state.parameters && this.state.parameters.length === 0
                                        ? <td>No Data</td>
                                        : ""}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">

                    <h2 class="popup-header" ref={subtitle => this.subtitle = subtitle}>Add new Parameter</h2>
                    <hr/> {this.state.errorMessage && <div class="isa_error">
                        <span class="glyphicon glyphicon-remove icon-pointer service-list-action"></span>
                        {this.state.errorMessage}
                    </div>
}
                    <div id="addparam">
                        <div className="row row-popup">
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="multicheck"
                                    value=""
                                    />
                                <label class="custom-control-label popup-sub" for="multicheck">Multi-values</label>
                            </div>
                        </div>

                        <div className="row row-popup">
                            <div class="col-lg-12 ">
                                <label className="digi-label">Name
                                </label>
                            </div>
                          </div>
                        <hr/>
                        <div id="single-form">
                            <div className="row row-popup">
                                <div class="col-lg-12">
                                    <input
                                        className="form-control digi-btn-rounded digi-textbox has-feedback placeholdergrey digi-textbox-td digi-textbox-td-popup"
                                        name="paramId"
                                        id="paramId"
                                        onBlur={(e) => this.validateParamName(e.target.value,'paramId')}></input>
                                </div>
                                
                            </div>
                            <div className="row">
                                <span>
                                    <button
                                        onClick={this.saveParameter}
                                        className="login-link-btn login-link-small">Save</button>
                                </span>
                                <span>
                                    <button onClick={this.closeModal} className="login-link-btn login-link-small">cancel</button>
                                </span>
                            </div>

                        </div>
                    </div>
                </Modal>
                           </div>

        );

    }
}

export default ServiceDetail;