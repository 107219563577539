import React from 'react';
import ReactDOM from 'react-dom';
import './css/bootstrap.min.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import EditService from './microservice/ServiceDetail';
import ServiceForm from './microservice/Serviceform';
import ServiceList from './microservice/ServiceList';
import ChannelForm from './channel/ChannelForm';
import ChannelList from './channel/ChannelList';
import EditChannel from './channel/ChannelDetail';
import Welcome from './Dashboard';
import Keycloak from 'keycloak-js';
import Logout from './utils/Logout';
import './css/bootstrap-glyphicons.min.css';
import './css/font-awesome.min.css';



const keycloak = Keycloak('/keycloak.json');

keycloak.init({onLoad: 'login-required'}).success((auth) => {

    if (!auth) {
        window.location.reload();
    } else {
        console.info("Authenticated");
    }
 
    //React Render
    ReactDOM.render(
    <Router>
    <div>
    <Route exact path='/' component={Welcome} />
    <Route path='/microservice/edit-service/:id' component={EditService} />
    <Route path='/microservice/add-service' component={ServiceForm} />
    <Route path='/microservice/list-service' component={ServiceList}/>
    <Route path='/channel/add-channel' component={ChannelForm}/>
    <Route path='/channel/list-channel' component={ChannelList}/>
    <Route path='/channel/edit-channel/:id' component={EditChannel}/>
    <Route path='/logout' component={()=><Logout keycloak ={keycloak}/>} />
    </div>
    </Router>,
     document.getElementById('root'));
    localStorage.setItem("react-token", keycloak.token);
    localStorage.setItem("react-refresh-token", keycloak.refreshToken);

    setTimeout(() => {
        keycloak.updateToken(70).success((refreshed) => {
            if (refreshed) {
                console.debug('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).error(() => {
            console.error('Failed to refresh token');
        });


    }, 60000)

}).error(() => {
    console.error("Authenticated Failed");
});
keycloak.onAuthLogout = () => {
    window.localStorage.setItem('authenticated', false);
    window.localStorage.setItem('authToken', null);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();