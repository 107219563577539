import React from "react";
import '../custom.css';
import axios from "axios";
import Menu from "../Menu";

class Serviceform extends  React.Component{
    state={
        serviceName:"",
        endPoint:"",
        roles:"",
        disablebtn:true
    }
    handleSubmit = (event) => { 
        event.preventDefault();
        var param=[];
        const paramobj = {
          name: "x-api-key",
          value: ""
      }
      param.push(paramobj);
        const obj = {
             serviceName: this.state.serviceName,
             endPoint:this.state.endPoint,
             roles:this.state.roles,
             params:JSON.stringify(param)
            };
        axios.post('/api/add-service',obj)
          .then(res =>{
            if(res.data.data==='success'){
              window.location.href="/microservice/edit-service/"+this.state.serviceName
            }
          })
          .catch(function (error) {
            console.log(error);
          })
    }
    isValidmsname = (event) => { 
        event.preventDefault();
        document.getElementById('serviceName').classList.remove("error");
        this.setState({disablebtn:false})
        axios.get('/api/service',{
            params:{
                authtoken:this.state.token,
                serviceName:this.state.serviceName
            }
        })
          .then( res=>{
            if(res.data.data.length>0){
              document.getElementById('serviceName').className+=" error" ;
              document.getElementById('serviceName-err').className="error-message show" ;
              this.setState({disablebtn:true})
            } else{
              document.getElementById('serviceName-err').className="error-message d-none" ;
            }
          })
          .catch(function (error) {
            console.log(error);
          })
    }
    handleChange = (e) => {
        if (["name", "value"].includes(e.target.className) ) {
          let parameters = [...this.state.parameters]
          parameters[e.target.dataset.id][e.target.className] = e.target.value.toUpperCase()
          this.setState({ parameters }, () => console.log(this.state.parameters))
        } else {
          this.setState({ [e.target.name]: e.target.value })
        }
      }

    render(){
        let {serviceName,endPoint} =this.state
        return(
            <div>
            <Menu/>
            
            <div className="service-form">
            <h2>Microservice Form</h2>
            <hr></hr>
            <form onSubmit={this.handleSubmit} onChange={this.handleChange} >
                <div className="field-wrap">
                <label htmlFor="serviceNane" className="digi-label">Service Name:</label>
                <input type="text" onBlur={this.isValidmsname} name="serviceName" id="serviceName" value={serviceName} className="digi-btn-rounded digi-textbox has-feedback placeholdergrey"></input>
                <span id="serviceName-err" className="error-message d-none">Service name is already used.</span>
                </div>
                <div className="field-wrap">
                <label htmlFor="endPoint" className="digi-label"> End Point: </label>
                <input type="text" name="endPoint" id="endPoint"  value={endPoint} className="digi-btn-rounded digi-textbox has-feedback placeholdergrey"></input>
                </div>
                <div className="field-wrap">
                <label htmlFor="roles" className="digi-label"> Roles: </label>
                <input type="text" name="roles" id="roles" className="digi-btn-rounded digi-textbox has-feedback placeholdergrey"></input>
                </div>
                <input type="submit" value="Submit" disabled={this.state.disablebtn} className="btn btn-primary digi-login-btn"/>
            </form>
            </div>
            </div>
            
        )
    }
}
export default Serviceform;