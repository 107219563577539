import React,{ Component }  from 'react';


class Logout extends Component{

    constructor(props) {
        super(props);
        this.state = { keycloak: this.props.keycloak};
      }

    componentDidMount(){
             var re = new RegExp(/^.*\//);
            this.state.keycloak.logout({'redirectUri':re.exec(window.location.href)}); 
        
    }
    render() {
        return (
            <div>Logout Successful</div>
        );
    }
}
export default Logout;