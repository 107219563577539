import React,{ Component }  from 'react';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import Menu from './Menu';
class Dashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            id: "",
            services:[],
            keycloak:this.props.keycloak
        };
       
    }
    render() {
        return (
            <div className="service-form">
            <Menu/>
            <div>
            <div className="welcome-banner">
            Welcome
            </div>
            </div>
            </div>
        );
    }
}
export default Dashboard;